<template>
  <div class="list">
    <Breadcrumb separator="->" :list="breadcrumbList"></Breadcrumb>
    <div class="list-container clearfix">
      <div class="pull-left list-container-aside" v-loading="loading">
        <div class="everything-nav-box">
          <div class="nav-item-title">{{title}}</div>
          <div class="nav-item-box">
            <div v-if="boxLeftList.length > 0" class="animation-box" :style="`transform: translateY(${boxLeftItemIndex * 44 - 44}px);`"></div>
            <div v-for="(item, index) of boxLeftList" :key="index"
              class="left-item cursor" :class="{'left-item-on':boxLeftItemIndex == index + 1}"
              @click="boxLeftItem(index, item)">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="pull-right list-container-main">
        <router-view/>
      </div>
    </div>
    <Member></Member>
  </div>
</template>

<script>
import { getListTreeById } from '@/api/web'
import Member from '@/components/member/Member'
export default {
  components: {
    Member
  },
  name: 'List',
  data () {
    return {
      loading: false,
      title: '',
      breadcrumbList: [
        { name: '首页', path: '/' },
        { name: '', path: '' },
        { name: '', path: '' }
      ],
      boxLeftItemIndex: 1,
      boxLeftList: []
    }
  },
  watch: {
    '$route.query.id': function (n, o) {
      if (n) {
        this.initialData()
      }
    }
  },
  created () {
    this.initialData()
  },
  mounted () {},
  methods: {
    initialData () {
      this.loading = true
      getListTreeById({ id: this.$route.query.id }).then(res => {
        this.loading = false
        if (res.code === '000') {
          const data = res.result[0]
          this.breadcrumbList[1].name = data.name
          this.title = data.name
          this.boxLeftItemIndex = 1
          if (data.children !== null) {
            this.boxLeftList = data.children
            if (this.$route.query.cid) {
              data.children.forEach((val, ind) => {
                if (val.id === this.$route.query.cid) {
                  this.breadcrumbList[2].name = val.name
                  this.boxLeftItemIndex = ind + 1
                }
              })
            } else {
              this.breadcrumbList[2].name = data.children[0].name
              if (this.$route.query.columnId) {
                data.children.forEach((val, ind) => {
                  if (val.id === this.$route.query.columnId) {
                    this.breadcrumbList[2].name = val.name
                    this.boxLeftItemIndex = ind + 1
                  }
                })
                return
              }
              let itemUrl = '/list/normal'
              const itemQuery = {
                id: this.$route.query.id
              }
              for (let i = 0; i < data.children.length; i++) {
                if (data.children[i].url.substring(0, 4) !== 'http') {
                  this.boxLeftItemIndex = i + 1
                  this.breadcrumbList[2].name = data.children[i].name
                  if (data.children[i].url) {
                    itemUrl = data.children[i].url
                  }
                  if (data.children[i].type === '1') {
                    itemQuery.cid = data.children[i].id
                  } else {
                    itemQuery.columnId = data.children[i].id
                  }
                  break
                }
              }
              this.$router.push({ path: itemUrl, query: itemQuery })
            }
          } else {
            this.boxLeftList = []
            if (data.type === '1') {
              this.$router.push({ path: '/list/normal', query: { id: this.$route.query.id, cid: this.$route.query.id } })
            } else {
              this.$router.push({ path: '/list/column', query: { id: this.$route.query.id, columnId: this.$route.query.id } })
            }
          }
        }
      })
    },
    openHref (url, flag) {
      if (flag === 1) {
        const { href } = this.$router.resolve({
          path: url
        })
        window.open(href, '_blank')
      } else {
        window.open(url, '_blank')
      }
    },
    boxLeftItem (index, item) {
      if (this.boxLeftItemIndex !== index + 1) {
        if (item.isTarget === '1') {
          if (item.url.substring(0, 4) === 'http') {
            this.openHref(item.url, 2)
            return
          }
          let url = '/list/normal'
          if (item.url) {
            url = item.url
          }
          if (item.type === '1') {
            this.openHref(`${url}?id=${this.$route.query.id}&cid=${item.id}`, 1)
          } else {
            this.openHref(`${url}?id=${this.$route.query.id}&columnId=${item.id}`, 1)
          }
        } else {
          if (item.url.substring(0, 4) === 'http') {
            window.location.href = item.url
            return
          }
          let url = '/list/normal'
          if (item.url) {
            url = item.url
          }
          this.boxLeftItemIndex = index + 1
          this.breadcrumbList[2].name = item.name
          if (item.type === '1') {
            this.$router.push({ path: url, query: { id: this.$route.query.id, cid: item.id } })
          } else {
            this.$router.push({ path: url, query: { id: this.$route.query.id, columnId: item.id } })
          }
        }
      } else {
        if (item.url.substring(0, 4) === 'http') {
          if (item.isTarget === '1') {
            this.openHref(item.url, 2)
          } else {
            window.location.href = item.url
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.list-container{
  padding-bottom: 15px;
  .list-container-main {
    width: 920px;
    border-radius: 6px;
    background-color: #ffffff;
  }
  .list-container-aside {
    width: 250px;
    background: linear-gradient(135deg, #165CFF 0%, #6795FF 100%);
    border-radius: 4px;
    .everything-nav-box {
      .nav-item-title {
        line-height: 30px;
        margin: 15px 0;
        line-height: 40px;
        color: #fff;
        position: relative;
        font-size: 22px;
        padding: 0 30px;
      }
      .nav-item-title::before {
        content: '';
        position: absolute;
        display: block;
        top: 8px;
        left: 10px;
        width: 4px;
        height: 25px;
        background: #FF9852;
      }
      .nav-item-box {
        position: relative;
        min-height: 450px;
        z-index: 2;
        padding-bottom: 50px;
        .left-item {
          width: 210px;
          margin-bottom: 10px;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          color: #ffffff;
          padding-left: 40px;
          transition: all .3s;
        }
        .left-item-on {
          font-weight: bold;
          color: #5286FF;
        }
      }
      .animation-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 210px;
        background-color: #ffffff;
        height: 34px;
        border-radius: 0 20px 20px 0;
        transition: all .3s;
        z-index: -1;
      }
    }
  }
}
</style>
